import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const workAroundMarginTopCss = css`
  @media (max-width: 992px) {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    margin-top: -30px;
  }
  @media (max-width: 576px) {
    margin-top: -100px;
  }
  @media (max-width: 380px) {
    margin-top: -180px;
  }
`

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query vCleanQuery {
      top: file(relativePath: { eq: "cover_工作區域-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="空間消毒服務 | 全面滲透 - 細菌無一倖免 | 安全可靠 | 櫻花家居服務"
        description="為預防武漢肺災(新冠肺炎)等疫症，櫻花家居推出空間消毒服務，針對空氣中的細菌病毒進行分解，營造潔淨衛生環境，適合辦公室，商場店舖，工廠大廈，家居任宅等室內場地。無刺激性，安全可靠。無腐蝕性，不會損害物質表面。可一次完成消毒殺菌功效。在二十一個國家取得專利。"
      />
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  【 全新空間消毒服務！辦公室、住宅、大廈進行大範圍清潔】
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div>
                <p>
                  <Img fluid={data.top.childImageSharp.fluid} />
                </p>
                <p>
                  為預防疫症，推出空間消毒服務，針對空氣中的細菌病毒進行分解，營造潔淨衛生的環境，適合辦公室丶商場店舖丶工廠大廈丶家居住宅等室內場地。
                  櫻花有<b>抗疫4式</b>幫你一次完成清潔及消毒！
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <iframe
              width="800"
              height="450"
              style={{ margin: "0 auto", border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowTransparency={true}
              allowFullScreen={true}
              data-src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fsakurahomeservices%2Fvideos%2F303357774233366%2F&amp;show_text=0&amp;width=800"
              className=" lazyloaded"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fsakurahomeservices%2Fvideos%2F303357774233366%2F&amp;show_text=0&amp;width=800"
            ></iframe>
          </Row>
          <Row css={workAroundMarginTopCss}>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/booking" className="button">
                  <b>立即預約</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/櫻花消毒技術" className="button">
                  <b>了解消毒四式</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default ThePage
